import moment from "moment";
import { LOGGED_IN_KEY } from "./constants";

export const formatCurrency = (amount) => {
  return "£" + amount.toLocaleString({}, { minimumFractionDigits: 2 });
};

export const formatDate = (date) => {
  return moment(date).format("D MMMM YYYY");
};

export const ensureLoggedInOrRedirect = (router) => {
  if (hasCookie(LOGGED_IN_KEY)) {
    return false;
  }
  router.push({ path: "/" });
  return true;
}

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}`;
}

export const deleteCookie = (name) => {
  document.cookie = name + '=;Max-Age=-1';
}

export const hasCookie = (name) => {
  // Gets all cookies. E.g. "Cookie1=true; Cookie2=false; Cookie3=value"
  const allCookies = document.cookie;
  // Split all cookies and check each cookie if they match argument.
  return allCookies.split(";")
    .some((item) => item.trim() === (`${name}=true`));
}
