<script>
import { BASE_API_URL } from "../constants";
import { formatCurrency, formatDate } from "../utils";

export default {
  name: "Order",
  props: {
    order: Object,
  },
  methods: {
    formattedDate(dateString) {
      const date = new Date(dateString);
      return formatDate(date);
    },
  },
  computed: {
    invoicePath() {
      return BASE_API_URL + "/DownloadInvoice/" + this.order.invoiceNumber;
    },
    invoiceAvailable() {
      return this.order.invoiceNumber != 0 && this.order.invoiceNumber != null;
    },
    showViewTickets() {
      return !this.order.items.some(
        (i) => i.ticketNumber == null || i.ticketNumber === ""
      );
    },
    showOrderTotal() {
      return this.order.amount > 0;
    },
    items() {
      const items = {};
      this.order.items.map((item) => {
        const existingQuantity = items[item.description] == null ? 0 : items[item.description].quantity;
        items[item.description] = {
          quantity: existingQuantity + 1,
          description: item.description,
        };
      });
      return Object.keys(items).map((key) => {
        if (items[key].quantity === 1) return items[key].description;
        else return `${items[key].quantity} x ${items[key].description}`;
      });
    },
    orderAmount() {
      return formatCurrency(this.order.amount);
    },
  },
};
</script>

<template>
  <div class="pink-border flex-display p-75">
    <div class="w-full p-relative p-b-40">
      <h2>{{ order.eventName }}</h2>
      <div>{{ formattedDate(order.eventDate) }}</div>
      <div>Order number: {{ order.orderNumber }}</div>
      <div>Order placed: {{ formattedDate(order.orderDate) }}</div>
      <div v-if="showOrderTotal">Total: {{ orderAmount }}</div>
      <ul>
        <li v-for="(item, index) in items" :key="index">
          {{ item }}
        </li>
      </ul>
      <div class="button-container p-absolute">
        <template v-if="invoiceAvailable">
          <form
            :action="invoicePath"
            class="margin-top w-1/2 display-inline-block"
          >
            <button type="submit">Download Invoice</button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>
